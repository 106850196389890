'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import { ButtonMain } from '@app/ui-kit';

import s from './ErrorPage.module.scss';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className={s.root}>
      <div className={s.errorBody}>
        <div className={s.name}>Something went wrong!</div>
        <div className={s.message}>{error.message}</div>
        <ButtonMain
          label="Try Again"
          onClick={() => window.location.reload()}
        />
      </div>
    </div>
  );
}
